const config = {
  DEFAULT_LANGUAGE: "fr",
  API_KEY: "CaDy4n7o", // this is the public key for the client (no secret key)
  FEEDBACK_MODULES_PROD: ["vote"],
  FEEDBACK_MODULES_DEV: ["vote"],
  FEEDBACK_SHOW_NEXT_STATE_IF: "VOTE:POSITIVE",
  // FEEDBACK_NEXT_MODULES:
  CHAT_MODE: true,
  INDICATE_MODE: "END_OF_PAGE",
};

export default config;
