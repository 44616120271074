import React from "react";

type LinkedTextProps = {
  markdown: string;
  className?: string;
};

type LinkedTextLineProps = {
  markdown: string;
};

// Function to split the markdown into paragraphs
const splitIntoParagraphs = (markdown: string): string[] => {
  return markdown
    .replaceAll("|||", "\n\n") // Custom delimiter for paragraphs
    .split("\n\n"); // Split into paragraphs by double new lines
};

// Function to parse inline links within a string
const parseLinks = (text: string): (string | JSX.Element)[] => {
  const linkRegex = /\[([^\]]+)\]\(((?:https?|tel|mailto):[^\s)]+)\)/g;
  const parts: (string | JSX.Element)[] = [];
  let lastIndex = 0;
  let match;

  // Iterate over all matches in the text for links
  while ((match = linkRegex.exec(text)) !== null) {
    // Add the text before the link
    parts.push(text.slice(lastIndex, match.index));

    // Add the link as an <a> element
    parts.push(
      <a
        key={match.index}
        href={match[2]}
        className="a--cta a--underline"
        target="_blank"
        rel="noopener noreferrer"
      >
        {match[1]}
      </a>,
    );
    lastIndex = linkRegex.lastIndex; // Update last index to the end of the match
  }

  // Add the remaining text after the last link
  parts.push(text.slice(lastIndex));

  return parts;
};

// Component to handle individual lines, including splitting and parsing links
export const LinkedTextLine: React.FC<LinkedTextLineProps> = ({ markdown }) => {
  // Replace custom delimiters and split the paragraph into lines
  const lines = markdown
    .replaceAll("||", "  \n") // Custom delimiter for new lines
    .split(/ {2,}\n/); // Split by line breaks

  return (
    <>
      {lines.map((line, lineIndex) => (
        <React.Fragment key={lineIndex}>
          {/* Parse links within each line */}
          {parseLinks(line)}
          {lineIndex < lines.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  );
};

export const LinkedText: React.FC<LinkedTextProps> = ({
  markdown,
  className,
}) => {
  const paragraphs = splitIntoParagraphs(markdown);

  return (
    <div className={className}>
      {paragraphs.map((text, index) => (
        <p key={index}>
          {/* Render LinkedTextLine component for each paragraph */}
          <LinkedTextLine markdown={text} />
        </p>
      ))}
    </div>
  );
};
